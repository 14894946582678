import {
  ImpersonationContext,
  getAvatarOptions,
  useApi,
} from '@monash/portal-frontend-common';
import { Alert, Loading } from '@monash/portal-react';
import { useContext, useEffect } from 'react';
import { StudentContext } from '../../../providers/StudentProvider';
import c from './avatar-selector.module.scss';
import Avatar from './avatar/Avatar';

const AvatarSelector = ({
  setSelectedAvatar,
  selectedAvatar,
  isUpdating,
  setIsAvatarOptionsLoaded,
}) => {
  const { mixAvatar } = useContext(StudentContext);
  const { currentUser } = useContext(ImpersonationContext);

  const {
    data: avatarOptions,
    call,
    isLoading,
    error: avatarOptionsError,
  } = useApi({ requestFn: getAvatarOptions, skipInitialCall: true });

  useEffect(() => {
    if (currentUser) {
      call();
    }
  }, [currentUser]);

  useEffect(() => {
    setIsAvatarOptionsLoaded(!isLoading);
  }, [isLoading]);

  return (
    <div className={c.avatarSelector}>
      <Loading isLoading={isLoading}>
        <div className={c.avatarOptions}>
          {mixAvatar && (
            <Avatar
              disabled={isUpdating}
              avatar={mixAvatar}
              setSelectedAvatar={setSelectedAvatar}
              selected={mixAvatar?.id === selectedAvatar?.id}
            />
          )}
          {avatarOptions?.map((avatarOption, i) => (
            <Avatar
              key={avatarOption.description}
              disabled={isUpdating}
              avatar={avatarOption}
              setSelectedAvatar={setSelectedAvatar}
              selected={avatarOption?.toyId === selectedAvatar?.toyId}
            />
          ))}
        </div>
        {avatarOptionsError && (
          <Alert type="error">
            Sorry, we are having some <strong>issues retrieving avatars</strong>
            , please try again later.
          </Alert>
        )}
      </Loading>
    </div>
  );
};

export default AvatarSelector;
