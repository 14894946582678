import {
  ImpersonationContext,
  courseEnrolmentStatusProps,
  sortCourseEnrolmentsByRelevance,
  useApi,
  getAvatar,
  getProfileData,
  getCourseProgression,
} from '@monash/portal-frontend-common';
import React, { useContext, useEffect } from 'react';

export const StudentContext = React.createContext();

const StudentProvider = ({ children }) => {
  const { currentUser } = useContext(ImpersonationContext);

  const {
    data: profileData,
    isLoading: loadingProfileData,
    error: profileDataError,
  } = useApi({ requestFn: getProfileData });

  const canHavePreferredFullName = Boolean(
    profileData?.preferredName && profileData?.lastName
  );

  const enrichedProfileData = {
    ...profileData,
    ...(canHavePreferredFullName && {
      preferredFullName: `${profileData.preferredName} ${profileData.lastName}`,
    }),
  };

  // Fetch course progress data
  useEffect(() => {
    if (currentUser && !currentUser?.embargo && !currentUser?.hasEncumbrances) {
      callGetCourseProgression();
    }
  }, [currentUser]);

  const {
    data: enrolments,
    isLoading: loadingEnrolments,
    error: enrolmentsError,
    call: callGetCourseProgression,
  } = useApi({ requestFn: getCourseProgression });

  const {
    data: avatar,
    isLoading: loadingAvatar,
    error: avatarError,
    call: callGetAvatar,
  } = useApi({ requestFn: getAvatar });

  const values = {
    enrolments:
      enrolments &&
      sortCourseEnrolmentsByRelevance(
        // Filter out invalid enrolments (eg. unconfirmed)
        enrolments?.filter(
          (enrolment) => courseEnrolmentStatusProps[enrolment.status]
        )
      ),
    profileData: enrichedProfileData,
    avatar,
    mixAvatar: avatar?.mixAvatar,
    portalAvatar: avatar?.portalAvatar,
    fullPageError:
      profileDataError && !currentUser.hasEncumbrances && enrolmentsError,
    avatarError,
    callGetAvatar,
    loadingProfileData,
    loadingEnrolments,
    loadingAvatar,
  };

  return (
    <StudentContext.Provider value={values}>{children}</StudentContext.Provider>
  );
};

export default StudentProvider;
