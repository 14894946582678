import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Icon,
  IconButton,
  Modal,
  ModalAction,
  ModalSection,
  LoadingIndicator,
} from '@monash/portal-react';
import {
  APIContext,
  AccessibilityContext,
  MenuContext,
} from '@monash/portal-frontend-common';
import { StudentContext } from '../../../../providers/StudentProvider';
import { useSnackbar } from '../../../../providers/SnackbarProvider';
import AvatarSelector from '../../avatar-selector/AvatarSelector';
import unavailableAvatarImageUrl from '../../../../../assets/images/avatar-unavailable.png';
import c from './user-avatar.module.scss';

const UserAvatar = () => {
  const { updateAvatar, deleteAvatar } = useContext(APIContext);
  const { avatarError, callGetAvatar, mixAvatar, loadingAvatar, portalAvatar } =
    useContext(StudentContext);
  const { size } = useContext(MenuContext);
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);
  const { addSnackbar } = useSnackbar();
  const [isAvatarOptionsLoaded, setIsAvatarOptionsLoaded] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const [selectingAvatar, setSelectingAvatar] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(
    portalAvatar || mixAvatar
  );
  const openSelectorModalButtonRef = useRef();
  const saveAvatarButtonRef = useRef();
  const mixAvatarInUse = portalAvatar === null;
  const modalTitle = 'Select avatar';

  // Focus on save button on selection
  useEffect(() => {
    saveAvatarButtonRef.current?.focus();
  }, [selectedAvatar]);

  // Focus on save button on error
  useEffect(() => {
    updateError && saveAvatarButtonRef.current?.focus();
  }, [updateError]);

  // Update avatar error
  const handleUpdateError = (error) => {
    setUpdateError(true);
    setIsUpdating(false);
    console.error(error);
  };

  // Exit avatar selector
  const closeSelectorModal = (updatedAvatar, notifyAvatarUpdated = false) => {
    setIsUpdating(false);
    setUpdateError(false);
    setSelectingAvatar(false);
    setIsAvatarOptionsLoaded(false);

    // Avatar has been updated (option 1 or 2), set selected avatar to the newly updated option
    if (updatedAvatar?.id || updatedAvatar?.toyId) {
      setSelectedAvatar(updatedAvatar);
    } else {
      // Avatar has not been updated, reset selected avatar back to current avatar
      setSelectedAvatar(portalAvatar || mixAvatar);
    }
    openSelectorModalButtonRef.current.focus();
    // notify avatar is updated when required
    if (notifyAvatarUpdated) {
      resetAppLiveMsgs();
      addSnackbar({
        message: 'Your avatar has been updated.',
        type: 'success',
      });
    }
  };

  // Update option 1: portal avatar
  const updatePortalAvatar = (avatar) => {
    updateAvatar(avatar)
      .then((r) => {
        callGetAvatar();
        closeSelectorModal(r, true);
      })
      .catch((error) => {
        handleUpdateError(error);
      });
  };

  // Update option 2: student image
  const removePortalAvatar = () => {
    deleteAvatar()
      .then(() => {
        callGetAvatar();
        closeSelectorModal(mixAvatar);
      })
      .catch((error) => {
        handleUpdateError(error);
      });
  };

  // Update avatar
  const updateUserAvatar = (selectedAvatar) => {
    setIsUpdating(true);
    setUpdateError(false);
    if (selectedAvatar.toyId) {
      // Update option 1
      updatePortalAvatar(selectedAvatar);
    }
    if (selectedAvatar.id) {
      // Update option 2
      removePortalAvatar();
    }
  };

  return (
    <div className={c.userAvatar}>
      {/* Avatar selector modal */}
      <Modal
        dataLoaded={isAvatarOptionsLoaded}
        open={selectingAvatar}
        onClose={closeSelectorModal}
        size={size}
        ariaLabel={modalTitle}
        ariaDescribedby={null}
      >
        <ModalSection
          title={modalTitle}
          titleTabIndex={null}
          ariaLabelledby="selectAvatarModalTitle"
          ariaDescribedby="selectAvatarModalContent"
        >
          <AvatarSelector
            isUpdating={isUpdating}
            selectedAvatar={selectedAvatar}
            setSelectedAvatar={setSelectedAvatar}
            setIsAvatarOptionsLoaded={setIsAvatarOptionsLoaded}
          />
        </ModalSection>
        <ModalAction position="center">
          {/* TODO: tackle the flex behaviours in the UI component instead */}
          <div className={c.modalAction}>
            {updateError && (
              <Alert type="error">
                Sorry, we're having{' '}
                <strong>trouble saving your avatar right now</strong> – come
                back and try again later.
              </Alert>
            )}
            <Button
              variant="primary"
              size={size === 'S' ? 'small' : 'medium'}
              ref={saveAvatarButtonRef}
              onClick={() => updateUserAvatar(selectedAvatar)}
              // TODO: deal with the gap time
              loading={isUpdating}
              loadingMessage="Updating Avatar"
              disabled={
                isUpdating ||
                (mixAvatarInUse
                  ? selectedAvatar === mixAvatar
                  : portalAvatar?.toyId === selectedAvatar?.toyId)
              }
            >
              Save
            </Button>
          </div>
        </ModalAction>
      </Modal>

      {/* Portal avatar in use */}
      {!loadingAvatar && portalAvatar && (
        <img src={portalAvatar.url} alt={portalAvatar.description} />
      )}

      {/* Student image in use */}
      {!loadingAvatar && mixAvatarInUse && mixAvatar && (
        <img src={`data:image/jpeg;base64,${mixAvatar?.image}`} alt="id card" />
      )}

      {/* Failed to get avatar or missing data */}
      {(avatarError || (!portalAvatar && !mixAvatar)) && !loadingAvatar && (
        <img src={unavailableAvatarImageUrl} alt="avatar unavailable" />
      )}

      {/* Loading user avatar */}
      {loadingAvatar && <LoadingIndicator />}

      {/* Avatar edit button */}
      <div className={c.editButtonWrapper}>
        <IconButton
          className={c.editButton}
          ref={openSelectorModalButtonRef}
          onClick={() => setSelectingAvatar(true)}
          variant="text"
          iconPosition="right"
          disabled={avatarError}
          icon={Icon.Pencil}
          color="var(--card-cta-bg-color)"
          aria-haspopup="dialog"
          aria-label="Edit avatar"
        />
      </div>
    </div>
  );
};

export default UserAvatar;
